import React from 'react';
import '../../styles/utils/Heart.scss';

const Heart = ({ left, top }) => {
    return (
        <div
            className="heart"
            style={{ left: `${left}px`, top: `${top}px` }}
        ></div>
    );
};

export default Heart;
