import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/utils/Header.scss';
import { useSelectedStoryID } from '../../SelectedBookContext';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const isHome = location.pathname === '/home';
    const isMyStorybook = location.pathname === '/myStorybook';
    const isStoryland = location.pathname === '/storyland';
    const isSelecting = location.pathname === '/selecting';

    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

    const toggleHamburgerMenu = () => {
        setShowHamburgerMenu(!showHamburgerMenu);
    };

    const USER_NAME = localStorage.getItem('userName') ?? '';
    const { STORYID } = useSelectedStoryID();

    const isStoriesPage = location.pathname === `/making/${STORYID}`;
    const isReadingPage = location.pathname === `/reading`;
    const headerClassName = `${
        isStoriesPage || isReadingPage ? 'hovered' : ''
    }`;
    const [isMouseOver, setIsMouseOver] = useState(false);
    const handleMouseEnter = () => setIsMouseOver(true);
    const handleMouseLeave = () => setIsMouseOver(false);

    return (
        <>
            <header
                id="header"
                className={headerClassName}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="inner">
                    <div className="header-left">
                        <h1
                            className="logo"
                            onClick={() => navigate(`/home`)}
                        >
                            <img
                                src={'/images/logo/default-logo.svg'}
                                alt="main-logo"
                            ></img>
                        </h1>
                        <nav className="nav">
                            <ul>
                                <li
                                    className={isHome ? 'active' : ''}
                                    onClick={() => navigate(`/home`)}
                                >
                                    홈
                                </li>
                                <li
                                    className={isMyStorybook ? 'active' : ''}
                                    onClick={() => navigate(`/myStorybook`)}
                                >
                                    나만의 서재
                                </li>
                                <li
                                    className={isStoryland ? 'active' : ''}
                                    onClick={() => navigate(`/storyland`)}
                                >
                                    스토리랜드
                                </li>
                                <li
                                    className={isSelecting ? 'active' : ''}
                                    onClick={() => navigate(`/selecting`)}
                                >
                                    동화 만들기
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <span
                            className="back-magickid-btn"
                            onClick={() => {
                                if (!isMouseOver) return;
                                window.close();
                            }}
                        >
                            <figure>
                                <img
                                    src={
                                        '/images/utils/button/back-magickid-3.svg'
                                    }
                                    alt=""
                                />
                            </figure>
                        </span>
                        <span className="user-id">
                            {USER_NAME.length > 3 && (
                                <>
                                    {USER_NAME}
                                    <span className="sir"> 님</span>
                                </>
                            )}
                        </span>
                    </div>
                    {/* -------------모바일 메뉴------------- */}
                    <div className="hamburger-mobile">
                        <button onClick={toggleHamburgerMenu}>
                            <figure>
                                <img
                                    src={'/images/header/menu.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>MENU</span>
                        </button>
                    </div>
                    {showHamburgerMenu && (
                        <div className="ham-fixed">
                            <div
                                className="close-m"
                                onClick={() => {
                                    setShowHamburgerMenu(false);
                                }}
                            >
                                <button>
                                    <img
                                        src={'/images/utils/close.png'}
                                        alt="close-btn"
                                    />
                                </button>
                            </div>
                            <p className="name-container">
                                {USER_NAME}
                                <span className="sir"> 님</span>
                            </p>
                            <ul className="navi">
                                <li
                                    className="my-library"
                                    onClick={() => {
                                        if (!isMouseOver) return;
                                        setShowHamburgerMenu(false);
                                        navigate(`/myStorybook`);
                                    }}
                                >
                                    나만의 서재
                                </li>
                                <li
                                    className="storyland"
                                    onClick={() => {
                                        if (!isMouseOver) return;
                                        setShowHamburgerMenu(false);
                                        navigate(`/storyland`);
                                    }}
                                >
                                    스토리랜드
                                </li>
                                <li
                                    className="storyland"
                                    onClick={() => {
                                        if (!isMouseOver) return;
                                        setShowHamburgerMenu(false);
                                        navigate(`/selecting`);
                                    }}
                                >
                                    동화 만들기
                                </li>
                                <li
                                    className="back-magic-kid"
                                    onClick={() => {
                                        if (!isMouseOver) return;
                                        window.close();
                                    }}
                                >
                                    매직포유로 돌아가기
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </header>
        </>
    );
};

export default Header;
