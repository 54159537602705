import React from 'react';
import '../../../styles/utils/button/PaginationButtons.scss';

const PaginationButtons = ({ onPrev, onNext, currentPage, totalPages }) => {
    return (
        <div className="button-container">
            <button
                onClick={onPrev}
                disabled={currentPage === 1}
            >
                이전
            </button>
            <button
                onClick={onNext}
                disabled={currentPage === totalPages}
            >
                다음
            </button>
        </div>
    );
};

export default PaginationButtons;
