// 2024-02-06 | 가로로 보기 모달창
import React, { useEffect, useState } from 'react';
import '../../../styles/utils/modal/LandScapeModal.scss';

const LandScapeModal = () => {
    const checkOrientation = () => {
        return window.matchMedia('(orientation: portrait)').matches;
    };

    const [isVisible, setIsVisible] = useState(checkOrientation());
    const [userClosedModal, setUserClosedModal] = useState(false);

    const handleClose = () => {
        setIsVisible(false);
        setUserClosedModal(true);
    };

    useEffect(() => {
        const handleResize = () => {
            if (!userClosedModal) {
                setIsVisible(checkOrientation());
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [userClosedModal]);

    useEffect(() => {
        const modalElement = document.querySelector('.landscape-modal');
        if (modalElement) {
            modalElement.style.display = isVisible ? 'block' : 'none';
        }
    }, [isVisible]);

    return (
        <>
            <section className="landscape-modal">
                <div className="inner">
                    <figure>
                        <img
                            src={'/images/utils/modal/landscape.svg'}
                            alt="landscape"
                        />
                    </figure>
                    <div className="land-txt-container">
                        <p>
                            스토리포유를 더 크고 넓게 보려면 태블릿을 가로로
                            해볼까요?
                        </p>
                        <button onClick={handleClose}>확인</button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LandScapeModal;
