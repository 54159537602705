import React, { useState, useEffect } from 'react';
import '../../../styles/utils/modal/BackgroundModal.scss';

const BackgroundModal = ({
    modalTitle,
    propImg = [],
    originImg,
    onImageSelect,
    onRegenerate,
    onClose,
}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(true);
    // const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        setIsButtonDisabled(selectedImage === null);
    }, [selectedImage]);

    const selectImage = (image) => {
        if (selectedImage === image) {
            setSelectedImage(null);
        } else {
            setSelectedImage(image);
        }
    };

    const handleChangeButtonClick = () => {
        console.log('ChangeButton Clicked!');
        onImageSelect(selectedImage);
        setIsModalOpen(false);
    };

    const handleRegenerateButtonClick = () => {
        console.log('RegenerateButton Clicked!');
        onRegenerate();
    };

    return (
        <div>
            <section className="image-modal-section">
                <figure
                    className="close-modal-btn"
                    onClick={onClose}
                >
                    <img
                        src={'/images/utils/close.png'}
                        alt=""
                    />
                </figure>
                <div className="modal-container">
                    <h3 className="modal-title">{modalTitle}</h3>
                    <div className="ai-image-container">
                        <div
                            className="ai-image-box"
                            onClick={() => selectImage(originImg)}
                        >
                            <div className="origin-text origin">
                                기존 배경그림
                                <div className="text-stroke origin">
                                    기존 배경그림
                                </div>
                            </div>
                            <figure
                                className={`ai-image ${
                                    selectedImage === originImg
                                        ? 'selected'
                                        : ''
                                }`}
                            >
                                <img
                                    src={originImg}
                                    alt="bg"
                                />
                            </figure>
                        </div>
                        <div
                            className="ai-image-box"
                            onClick={() => selectImage(propImg[0])}
                        >
                            <div className="origin-text new">
                                새로 만들어진 배경그림
                                <div className="text-stroke new">
                                    새로 만들어진 배경그림
                                </div>
                            </div>
                            <figure
                                className={`ai-image ${
                                    selectedImage === propImg[0]
                                        ? 'selected'
                                        : ''
                                }`}
                            >
                                <img
                                    src={propImg[0]}
                                    alt="bg"
                                />
                            </figure>
                        </div>
                    </div>
                    <div className="choose-btn-container">
                        <button
                            className="class-btns re-make"
                            onClick={handleRegenerateButtonClick}
                        >
                            이미지 재생성
                        </button>
                        <button
                            className={`class-btns confirm-image ${
                                isButtonDisabled ? 'disabled' : ''
                            }`}
                            onClick={handleChangeButtonClick}
                            disabled={isButtonDisabled}
                        >
                            선택하기
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BackgroundModal;
