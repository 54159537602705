import React, { useState, useEffect } from 'react';
import '../styles/pages/MyStorybook.scss';
import { getMybookList } from '../api/LibraryAPI';
import loginSessionCheck from '../components/utils/LoginSessionCheck';
import BookCoverList from '../components/utils/BookCoverList';
import CategoryList from '../components/utils/CategoryList';
import { useNavigate } from 'react-router-dom';
import AllViewModal from '../components/utils/modal/AllViewModal';
import Wrapper from '../components/utils/Wrapper';
import PaginationButtons from '../components/utils/button/PaginationButtons';

const MyStorybook = () => {
    const navigate = useNavigate();
    const USER_ID = localStorage.getItem('userID');
    // const USER_ID = '1000100205';

    const [bookList, setBookList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [booksPerPage, setBooksPerPage] = useState(8);
    const categories = ['전체동화'];
    const [selectedCategory, setSelectedCategory] = useState('전체동화');
    const [showAllModal, setShowAllModal] = useState(false);

    useEffect(() => {
        if (showAllModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [showAllModal]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getMybookList(USER_ID);
                if (res.success) {
                    console.log('@@ data =>', res.data);
                    setBookList(res.data);
                } else {
                    console.error('API 에러:', res.error.errMsg);
                }
            } catch (error) {
                console.error('API 호출 중 에러 발생:', error);
            }
        };
        fetchData();
    }, [USER_ID]);

    useEffect(() => {
        const updateBooksPerPage = () => {
            if (window.innerWidth < 1025) {
                setBooksPerPage(4);
            } else if (window.innerWidth < 1440) {
                setBooksPerPage(6);
            } else {
                setBooksPerPage(8);
            }
        };

        updateBooksPerPage();
        window.addEventListener('resize', updateBooksPerPage);
        return () => {
            window.removeEventListener('resize', updateBooksPerPage);
        };
    }, []);

    const totalPages = Math.ceil(bookList.length / booksPerPage);

    // 페이징 핸들러
    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const handleClose = () => {
        setShowAllModal(false);
    };

    const handleBookSelect = (book) => {
        console.log('선택된 책:', book);
        localStorage.setItem('book_id', book.id);
        navigate('/reading', {
            state: {
                bookList: book,
                pageName: 'myStorybook',
                bookID: book.id,
            },
        });
    };

    return (
        <Wrapper>
            <section className="mystorybook-section">
                <div className="inner">
                    <figure
                        className="all-view-btn"
                        onClick={() => {
                            setShowAllModal(true);
                        }}
                    >
                        <img
                            src={'/images/utils/button/all-view-my.svg'}
                            alt=""
                        />
                    </figure>
                    <h3 className="storybook-tit">
                        <figure>
                            <img
                                src={'/images/mystorybook/mystorybook-tit.svg'}
                                alt=""
                            />
                        </figure>
                    </h3>
                    <div className="mybook-container">
                        <div className="mybook-box left">
                            <CategoryList
                                categories={categories}
                                selectedCategory={selectedCategory}
                                onSelectCategory={setSelectedCategory}
                            />
                        </div>
                        <div className="mybook-box right">
                            <div className="box-title">
                                <p>총 {bookList.length}편</p>
                                <select>
                                    <option>추천순</option>
                                </select>
                            </div>
                            <BookCoverList
                                books={bookList}
                                currentPage={currentPage}
                                pageName="myStorybook"
                                onStorySelect={handleBookSelect}
                            />
                            <PaginationButtons
                                onPrev={handlePrevPage}
                                onNext={handleNextPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                            />
                        </div>
                    </div>
                </div>
                {/* inner-end */}
                <figure className="navigate-char">
                    <img
                        src={'/images/mystorybook/storybook-char.svg'}
                        alt=""
                    />
                </figure>
            </section>
            {showAllModal && (
                <AllViewModal
                    bookList={bookList}
                    onClose={handleClose}
                    onStorySelect={handleBookSelect}
                />
            )}
        </Wrapper>
    );
};

export default loginSessionCheck(MyStorybook);
