import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/pages/Selecting.scss';
import loginSessionCheck from '../components/utils/LoginSessionCheck';
import { getStoryList } from '../api/StoryAPI';
import { useSelectedStoryID } from '../SelectedBookContext';
import { ERROR } from '../utils/Constants';
import BookCoverList from '../components/utils/BookCoverList';
import AllViewModal from '../components/utils/modal/AllViewModal';
import Wrapper from '../components/utils/Wrapper';
import PaginationButtons from '../components/utils/button/PaginationButtons';

const Selecting = () => {
    const USER_ID = localStorage.getItem('userID');
    // const USER_ID = '1000100205';

    const tabMenu = [
        { id: 'all', label: '전체' },
        { id: 'comic', label: '재미있는 창작동화' },
        { id: 'education', label: '교훈을 주는 창작동화' },
    ];
    const { setStoryId } = useSelectedStoryID();
    const [storyList, setStoryList] = useState([]);
    const [activeTab, setActiveTab] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [booksPerPage, setBooksPerPage] = useState(6);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedStory, setSelectedStory] = useState(null);
    const [showAllModal, setShowAllModal] = useState(false);
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    // testing
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (showAllModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [showAllModal]);

    const navigate = useNavigate();

    useEffect(() => {
        callStoryListAPIFunc();
        setSelectedStory(null);
        setCurrentPage(1);
    }, [activeTab]);

    useEffect(() => {
        const updateBooksPerPage = () => {
            if (window.innerWidth < 1025) {
                setBooksPerPage(4);
            } else if (window.innerWidth < 1440) {
                setBooksPerPage(6);
            } else {
                setBooksPerPage(8);
            }
        };
        updateBooksPerPage();
        window.addEventListener('resize', updateBooksPerPage);
        return () => {
            window.removeEventListener('resize', updateBooksPerPage);
        };
    }, []);

    const callStoryListAPIFunc = () => {
        const response = getStoryList(USER_ID);

        response.then((res) => {
            if (res.success) {
                console.log('@@ data =>', res.data);
                setStoryList(() => {
                    if (activeTab === 'all') {
                        const filteredStory = res.data.filter(
                            (item) =>
                                item.category === 'comic' ||
                                item.category === 'education'
                        );
                        return filteredStory;
                    } else if (activeTab === 'comic') {
                        const filteredStory = res.data.filter(
                            (item) => item.category === 'comic'
                        );
                        return filteredStory;
                    } else if (activeTab === 'education') {
                        const filteredStory = res.data.filter(
                            (item) => item.category === 'education'
                        );
                        return filteredStory;
                    }
                });
                setIsLoading(false);
            } else {
                setShowErrorModal(true);
                setErrorMessage(ERROR.busy);
                console.log(res.error.errMsg);
            }
        });
    };

    // 줄거리 자르기
    const truncateText = (text, limit = 100) => {
        if (text.length <= limit) return text;
        return text.slice(0, limit) + '...';
    };

    const handleBookSelect = (selectedBook) => {
        //NOTE: 마법콩 확인 필요! 잔여 api호출
        setStoryId(selectedBook.story_id);
        if (selectedBook.description != '수정 필요')
            navigate(`/making/${selectedBook.story_id}`);
        else alert('해당 동화는 준비중입니다.');
    };

    const totalPages = Math.ceil(storyList.length / booksPerPage);

    const handleSelectStory = (story) => {
        setSelectedStory(story);
    };

    const handleClose = () => {
        setShowAllModal(false);
    };

    // 페이징 핸들러
    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };
    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    return (
        <Wrapper>
            <section className="selecting-section">
                <div className="inner">
                    <>
                        <figure
                            className="all-view-btn"
                            onClick={() => {
                                setShowAllModal(true);
                            }}
                        >
                            <img
                                src={
                                    '/images/utils/button/all-view-selecting.svg'
                                }
                                alt=""
                            />
                        </figure>
                        <h3 className="selecting-tit">
                            <figure>
                                <img
                                    src={'/images/selecting/selecting-tit.svg'}
                                    alt=""
                                />
                            </figure>
                        </h3>
                        <div className="selecting-container">
                            <div className="selecting-box left">
                                {selectedStory ? (
                                    <div className="selected-story-detail">
                                        <div className="selected-story-detail-cover">
                                            <figure className="selected-story-cover">
                                                <img
                                                    src={
                                                        selectedStory.bookcover
                                                    }
                                                    alt={selectedStory.title}
                                                />
                                            </figure>
                                            <h4 className="selected-story-title">
                                                {selectedStory.title}
                                            </h4>
                                            <div className="book-shadow"></div>
                                            <div className="book-white"></div>
                                        </div>
                                        <h4 className="selected-story-plot-title">
                                            줄거리
                                        </h4>
                                        <p
                                            className={`selected-story-plot ${
                                                descriptionExpanded
                                                    ? 'expanded'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                setDescriptionExpanded(
                                                    !descriptionExpanded
                                                )
                                            }
                                        >
                                            {descriptionExpanded
                                                ? selectedStory.description
                                                : truncateText(
                                                      selectedStory.description,
                                                      100
                                                  )}
                                        </p>
                                        <button
                                            className={`plot-more-view ${
                                                descriptionExpanded
                                                    ? 'expanded'
                                                    : 'collapsed'
                                            }`}
                                            onClick={() =>
                                                setDescriptionExpanded(
                                                    !descriptionExpanded
                                                )
                                            }
                                        >
                                            {descriptionExpanded
                                                ? '- 줄거리 접기'
                                                : '+ 줄거리 더 보기'}
                                        </button>
                                        <div className="button-group">
                                            <button
                                                className="cancel-btn"
                                                onClick={() =>
                                                    setSelectedStory(null)
                                                }
                                            >
                                                취소하기
                                            </button>
                                            <button
                                                className="make-btn"
                                                onClick={() =>
                                                    handleBookSelect(
                                                        selectedStory
                                                    )
                                                }
                                            >
                                                동화 만들기
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="before-select">
                                        <p className="left-title">
                                            나만의 동화를 만들어봐요!
                                        </p>
                                        <p className="left-desc">
                                            오른쪽 화면에서
                                            <br />
                                            <span>만들고 싶은 동화를 클릭</span>
                                            하면
                                            <br />
                                            동화의 줄거리를 확인하고
                                            <br />
                                            나만의 멋진 동화를 만들 수 있어요!
                                        </p>
                                        <figure className="char">
                                            <img
                                                src={
                                                    '/images/selecting/selecting-char.svg'
                                                }
                                                alt="캐릭터"
                                            />
                                        </figure>
                                    </div>
                                )}
                            </div>
                            <div className="selecting-box right">
                                <div className="box-title">
                                    <p>총 {storyList.length}편</p>
                                    <select>
                                        <option>추천순</option>
                                    </select>
                                </div>
                                <BookCoverList
                                    books={storyList}
                                    currentPage={currentPage}
                                    booksPerPage={booksPerPage}
                                    onStorySelect={handleSelectStory}
                                />
                                <PaginationButtons
                                    onPrev={handlePrevPage}
                                    onNext={handleNextPage}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                />
                            </div>
                        </div>
                    </>
                </div>
            </section>
            {showAllModal && (
                <AllViewModal
                    bookList={storyList}
                    onClose={handleClose}
                    onStorySelect={handleBookSelect}
                />
            )}
        </Wrapper>
    );
};

export default loginSessionCheck(Selecting);
