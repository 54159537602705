import axios from 'axios';

export const performRequest = async (method, url, data = null, responseType = 'json') => {
    try {
        const config = {
            method: method,
            url: url,
            ...(data && { data: data }),
            responseType: responseType, // 📌 TTS 요청인 경우 'blob' 처리
        };

        const response = await axios(config);

        if (response.status === 200 || response.status === 201) {
            if (responseType === 'blob') {
                // 📌 TTS 요청이면 Blob 데이터를 반환
                return {
                    success: true,
                    data: response.data, // Blob 데이터 반환
                    error: '',
                };
            } else {
                // 📌 일반 JSON 응답
                return {
                    success: response.data.success,
                    data: response.data.data,
                    error: '',
                };
            }
        } else {
            return {
                success: false,
                error: response.data.data,
            };
        }
    } catch (err) {
        return {
            success: false,
            error: err.response ? err.response.data : err.message,
        };
    }
};