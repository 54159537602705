import React, { useEffect, useState } from 'react';
import '../styles/pages/StoryLand.scss';
import CategoryList from '../components/utils/CategoryList';
import BookCoverList from '../components/utils/BookCoverList';
import { getStoryLandList } from '../api/LibraryAPI';
import AllViewModal from '../components/utils/modal/AllViewModal';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../components/utils/Wrapper';
import SeriesBookList from '../components/utils/SeriesBookList';
import PaginationButtons from '../components/utils/button/PaginationButtons';

const StoryLand = () => {
    const navigate = useNavigate();
    const [bookList, setBookList] = useState([]);
    const [displayedBooks, setDisplayedBooks] = useState([]);
    const [booksPerPage, setBooksPerPage] = useState(8);
    const [selectedCategory, setSelectedCategory] = useState('전체동화');
    const [selectedBook, setSelectedBook] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAllModal, setShowAllModal] = useState(false);
    const categories = ['전체동화', '단편 동화', '시리즈 동화'];

    // const handleBookSelect = (book) => {
    //     setSelectedBook(book);
    //     // 선택 후 모달 닫기 또는 원하는 추가 작업 실행
    //     setShowAllModal(false);
    //     navigate('/reading', {
    //         state: {
    //             bookList: book[i],
    //             pageName: pageName,
    //             bookID: book_id,
    //         },
    //     });
    // };

    useEffect(() => {
        if (showAllModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [showAllModal]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getStoryLandList();
                if (res.success) {
                    setBookList(res.data);
                } else {
                    console.error('API 에러:', res.error.errMsg);
                }
            } catch (error) {
                console.error('API 호출 중 에러 발생:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        let filteredBooks = [];
        switch (selectedCategory) {
            case '전체동화':
                filteredBooks = bookList;
                break;
            case '단편 동화':
                filteredBooks = bookList.filter((book) => !book.is_series);
                break;
            case '시리즈 동화':
                filteredBooks = bookList.filter((book) => book.is_series);
                break;
            default:
                filteredBooks = bookList;
        }
        setDisplayedBooks(filteredBooks);
        setCurrentPage(1);
    }, [selectedCategory, bookList]);

    useEffect(() => {
        const updateBooksPerPage = () => {
            if (window.innerWidth < 1025) {
                setBooksPerPage(4);
            } else if (window.innerWidth < 1440) {
                setBooksPerPage(6);
            } else {
                setBooksPerPage(8);
            }
        };

        updateBooksPerPage();
        window.addEventListener('resize', updateBooksPerPage);
        return () => {
            window.removeEventListener('resize', updateBooksPerPage);
        };
    }, []);

    const totalPages = Math.ceil(displayedBooks.length / booksPerPage);

    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };
    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const handleClose = () => {
        setShowAllModal(false);
    };

    const handleBookSelect = (book) => {
        console.log('선택된 책:', book);
        localStorage.setItem('book_id', book.id);
        navigate('/reading', {
            state: {
                bookList: book,
                pageName: 'storyland',
                bookID: book.id,
            },
        });
    };

    return (
        <Wrapper>
            <section className="storyland-section">
                <div className="inner">
                    <figure
                        className="all-view-btn"
                        onClick={() => {
                            setShowAllModal(true);
                        }}
                    >
                        <img
                            src={'/images/utils/button/all-view-storyland.svg'}
                            alt=""
                        />
                    </figure>
                    <h3 className="storyland-tit">
                        <figure>
                            <img
                                src={'/images/storyland/storyland-tit.svg'}
                                alt=""
                            />
                        </figure>
                    </h3>
                    <div className="storyland-container">
                        <div className="str-box left">
                            <CategoryList
                                categories={categories}
                                selectedCategory={selectedCategory}
                                onSelectCategory={setSelectedCategory}
                            />
                        </div>
                        <div
                            className={`str-box right ${
                                selectedCategory === '시리즈 동화'
                                    ? 'series'
                                    : ''
                            }`}
                        >
                            <div className="box-title">
                                <p>총 {displayedBooks.length}편</p>
                                <select>
                                    <option>추천순</option>
                                </select>
                            </div>
                            {selectedCategory === '시리즈 동화' ? (
                                <SeriesBookList
                                    books={displayedBooks}
                                    onStorySelect={handleBookSelect}
                                />
                            ) : (
                                <>
                                    <BookCoverList
                                        books={displayedBooks}
                                        currentPage={currentPage}
                                        booksPerPage={booksPerPage}
                                        pageName="storyland"
                                        onStorySelect={handleBookSelect}
                                    />
                                    <PaginationButtons
                                        onPrev={handlePrevPage}
                                        onNext={handleNextPage}
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <figure className="navigate-char">
                    <img
                        src={'/images/storyland/storyland-char.svg'}
                        alt=""
                    />
                </figure>
            </section>
            {showAllModal && (
                <AllViewModal
                    bookList={bookList}
                    onClose={handleClose}
                    onStorySelect={handleBookSelect}
                />
            )}
        </Wrapper>
    );
};

export default StoryLand;
