import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/home/Home.scss';
import BookCover from '../components/utils/BookCover';
import BookCoverList from '../components/utils/BookCoverList';
import Icon1 from '../assets/book-icon-1.json';
import Icon2 from '../assets/book-icon-2.json';
import Icon3 from '../assets/book-icon-3.json';
import Lottie from 'lottie-react';
import Wrapper from '../components/utils/Wrapper';

const Home = () => {
    const navigate = useNavigate();

    const MockBestBooks = [
        { id: 1, title: '책 1', bookcover: '/images/testStoryland/1.png' },
        { id: 2, title: '책 2', bookcover: '/images/testStoryland/2.png' },
        { id: 3, title: '책 3', bookcover: '/images/testStoryland/3.png' },
    ];

    return (
        <Wrapper>
            <section className="home-wrapper">
                <div className="inner">
                    <div className="seller-container">
                        <div className="storyland-best">
                            <div className="title">
                                스토리랜드 <span>Best Seller</span>
                            </div>
                            <div className="container">
                                <div className="container-bg">
                                    <div
                                        className="more-view-btn"
                                        onClick={() => navigate(`/storyland`)}
                                    >
                                        <p>더 보기</p>
                                        <div className="plus-icon">+</div>
                                    </div>
                                    <BookCoverList
                                        books={MockBestBooks}
                                        booksPerPage={3}
                                        variant="home"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="create-best">
                            <div className="title">
                                <figure className="light-logo">
                                    <img
                                        src={'/images/logo/logo-light.svg'}
                                        alt="main-logo"
                                    ></img>
                                </figure>
                                창작동화 <span>Best Seller</span>
                            </div>
                            <div className="container">
                                <div className="container-bg">
                                    <div className="more-view-btn">
                                        <p>더 보기</p>
                                        <div className="plus-icon">+</div>
                                    </div>
                                    <BookCoverList
                                        books={MockBestBooks}
                                        booksPerPage={3}
                                        variant="home"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="experience-title">
                        <figure>
                            <img
                                src={'/images/home/home-ex-title.svg'}
                                alt=""
                            />
                        </figure>
                    </h3>
                    <div className="experience-container">
                        <figure className="book-bg">
                            <img
                                src={'/images/home/book-bg.svg'}
                                alt=""
                            />
                        </figure>
                        <div
                            className="book-icon icon-1"
                            onClick={() => navigate(`/selecting`)}
                        >
                            <Lottie
                                animationData={Icon1}
                                loop={true}
                            />
                            <figure className="title-figure">
                                <img
                                    src={'/images/home/selecting-tit.svg'}
                                    alt="동화만들기 텍스트"
                                />
                            </figure>
                        </div>
                        <div
                            className="book-icon icon-2"
                            onClick={() => navigate(`/myStorybook`)}
                        >
                            <Lottie
                                animationData={Icon2}
                                loop={true}
                            />
                            <figure className="title-figure">
                                <img
                                    src={'/images/home/my-tit.svg'}
                                    alt="나만의 서재 텍스트"
                                />
                            </figure>
                        </div>
                        <div
                            className="book-icon icon-3"
                            onClick={() => navigate(`/storyland`)}
                        >
                            <Lottie
                                animationData={Icon3}
                                loop={true}
                            />
                            <figure className="title-figure">
                                <img
                                    src={'/images/home/str-tit.svg'}
                                    alt="스토리랜드 텍스트"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
                <figure className="shelf left">
                    <img
                        src={'/images/home/shelf-left.svg'}
                        alt=""
                    />
                </figure>
                <figure className="shelf right">
                    <img
                        src={'/images/home/shelf-right.svg'}
                        alt=""
                    />
                </figure>
                <div className="bottom"></div>
            </section>
        </Wrapper>
    );
};

export default Home;
