import React, { useState, useEffect, useRef } from 'react';
import '../styles/pages/Making.scss';
import ArrowButton from '../components/utils/button/ArrowButton';
import { useNavigate, useLocation } from 'react-router-dom';

// NOTE: Internal Dependencies.
import FinalReadingModal from '../components/reading/FinalReadingModal';
import LandScapeModal from '../components/utils/modal/LandScapeModal';
import { logStorylandReading, generateTTS } from '../api/LibraryAPI';
import ErrorModal from '../components/utils/modal/ErrorModal';
import { ERROR } from '../utils/Constants';
import StoryImage from '../components/utils/StoryImage';

const Reading = () => {
    const USER_ID = localStorage.getItem('userID');
    // const USER_ID = '1000100205';

    const location = useLocation();
    const { bookList, pageName, bookID } = location.state;
    const story = bookList.contents_json.story;

    const [index, setIndex] = useState(-1);
    const [showFinalReadingModal, setShowFinalReadingModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [bgImage, setBgImage] = useState('');
    const [contentText, setContentText] = useState([]); // 텍스트가 정상적으로 표시되도록 수정
    const [speechState, setSpeechState] = useState(false);
    const [audioCache, setAudioCache] = useState({}); // TTS 캐싱
    const [imageSrc, setImageSrc] = useState(
        '/images/utils/button/music-on.svg'
    ); // 기본 아이콘: 음성 듣기
    const audioRef = useRef(new Audio()); // HTMLAudioElement 사용
    const navigate = useNavigate();

    // 진행 중인 TTS 요청을 추적 (중복 호출 방지)
    const fetchingRef = useRef({});

    useEffect(() => {
        if (pageName === 'storyland') {
            callLogReadingAPIFunc();
        }
    }, []);

    // 독서 기록 API 호출
    const callLogReadingAPIFunc = () => {
        logStorylandReading(USER_ID, bookID).then((res) => {
            if (!res.success) {
                setErrorMessage(ERROR.lack);
                setShowErrorModal(true);
            }
        });
    };

    // TTS 호출 및 캐싱 (index === -1인 경우 제목 텍스트 사용)
    const fetchTTS = async (pageIndex) => {
        if (audioCache[pageIndex] || fetchingRef.current[pageIndex]) return; // 이미 캐싱되었거나 진행 중이면 요청 X

        fetchingRef.current[pageIndex] = true;
        let textContent = '';
        if (pageIndex === -1) {
            textContent = bookList.title;
        } else {
            textContent = story[pageIndex]?.page_content || '';
        }

        try {
            const response = await generateTTS(
                USER_ID,
                textContent,
                'vdain',
                0
            );
            if (response.success) {
                const audioBlob = new Blob([response.data], {
                    type: 'audio/mpeg',
                });
                const audioURL = URL.createObjectURL(audioBlob);
                setAudioCache((prevCache) => ({
                    ...prevCache,
                    [pageIndex]: audioURL,
                }));
            } else {
                throw new Error('TTS API 오류');
            }
        } catch (error) {
            console.error('TTS 요청 오류:', error);
        } finally {
            fetchingRef.current[pageIndex] = false;
        }
    };

    // 페이지 이동
    const handleButtonClick = (direction) => {
        setIndex((prevIndex) => {
            let newIndex = prevIndex + (direction === 'left' ? -1 : 1);
            if (newIndex < -1) {
                newIndex = story.length - 1;
            } else if (newIndex >= story.length) {
                setShowFinalReadingModal(true);
                return prevIndex;
            }
            return newIndex;
        });
    };

    // 음성 재생/정지 컨트롤 및 이미지 업데이트 (버튼 클릭 시)
    const handleSpeechState = () => {
        // 버튼 클릭 시 단순히 음성 듣기 상태와 아이콘만 토글합니다.
        if (!speechState) {
            setImageSrc('/images/utils/button/music-off.svg');
        } else {
            audioRef.current.pause();
            setImageSrc('/images/utils/button/music-on.svg');
        }
        setSpeechState((prev) => !prev);
    };

    // 페이지 변화에 따라 UI 업데이트 및 TTS 호출 (음성 듣기 활성화된 경우에만)
    useEffect(() => {
        if (index === -1) {
            console.log('title: ', bookList.title);
            setBgImage(bookList.bookcover);
            setContentText(bookList.title);
            setShowFinalReadingModal(false);
            if (speechState) {
                if (!audioCache[-1]) fetchTTS(-1);
                if (story.length > 0 && !audioCache[0]) fetchTTS(0);
            }
        } else {
            setBgImage(story[index]?.background || '');
            let contents = story[index]?.page_content || '';
            if (contents.includes('\\'))
                contents = contents.replaceAll('\\', '');
            contents = contents
                .split('. ')
                .map((sentence) => sentence.trim() + '.');
            setContentText(contents);
            console.log('contentText: ', contents);

            if (speechState) {
                if (!audioCache[index]) fetchTTS(index);
                if (index + 1 < story.length && !audioCache[index + 1]) {
                    fetchTTS(index + 1);
                }
            }
        }
    }, [index, speechState]);

    // speechState가 true이고, audioCache가 업데이트되면 자동 재생
    useEffect(() => {
        if (speechState) {
            audioRef.current.pause();
            if (audioCache[index]) {
                audioRef.current.src = audioCache[index];
                audioRef.current
                    .play()
                    .catch((error) =>
                        console.error('Audio play error:', error)
                    );
            }
        }
    }, [index, speechState, audioCache]);

    // 음성 재생이 종료되면, 이미지 아이콘만 업데이트 (speechState는 유지)
    const handleAudioEnd = () => {
        setImageSrc('/images/music-off.svg');
    };

    // 동화 읽기 종료 시 캐시 삭제
    const handleExit = () => {
        setAudioCache({});
        setShowFinalReadingModal(false);
    };

    return (
        <>
            <section className="stories-section">
                {showErrorModal && (
                    <ErrorModal
                        error_message={errorMessage}
                        onClose={() => {
                            setShowErrorModal(false);
                            navigate('/storyland');
                        }}
                    />
                )}
                <StoryImage
                    src={bgImage}
                    alt="bg-img"
                />
                <ArrowButton
                    onClick={() => handleButtonClick('left')}
                    direction="left"
                    className="story-btn prev-btn"
                />
                <ArrowButton
                    onClick={() => handleButtonClick('right')}
                    direction="right"
                    className="story-btn next-btn"
                />
                <div
                    className={
                        typeof contentText === 'string'
                            ? 'story-contents-container title'
                            : 'story-contents-container'
                    }
                >
                    <ul
                        className={
                            typeof contentText === 'string'
                                ? 'story-contents title'
                                : 'story-contents'
                        }
                    >
                        {typeof contentText === 'string' ? (
                            <>
                                {contentText}
                                <div className="text-stroke">{contentText}</div>
                            </>
                        ) : (
                            <>
                                {contentText.map((sentence, i) => (
                                    <li
                                        key={i}
                                        className="origin-text"
                                    >
                                        {sentence.trim()}
                                        <div className="text-stroke">
                                            {sentence.trim()}
                                        </div>
                                    </li>
                                ))}
                            </>
                        )}
                    </ul>
                </div>
                {/* 음성 듣기 버튼 */}
                <button
                    onClick={(event) => {
                        event.stopPropagation();
                        handleSpeechState();
                    }}
                    className="text-music-btn"
                >
                    <img
                        src={imageSrc}
                        alt="음성 듣기 버튼"
                    />
                </button>
                <audio
                    ref={audioRef}
                    onEnded={handleAudioEnd}
                />
                <div className="numbering">{index + 1}</div>
            </section>
            {showFinalReadingModal && (
                <FinalReadingModal
                    onBack={() => setIndex(-1)}
                    pageName={pageName}
                    onClose={handleExit}
                />
            )}
            <LandScapeModal />
        </>
    );
};

export default Reading;
