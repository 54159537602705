import React, { useEffect, useState } from 'react';
import BookCover from './BookCover';
import { useNavigate } from 'react-router-dom';

const BookCoverList = ({
    books,
    currentPage,
    booksPerPage,
    pageName,
    onStorySelect,
    variant,
}) => {
    const [dynamicBooksPerPage, setDynamicBooksPerPage] = useState(8);
    const navigate = useNavigate();

    useEffect(() => {
        if (booksPerPage == null && variant !== 'home') {
            const updateBooksPerPage = () => {
                if (window.innerWidth < 1025) {
                    setDynamicBooksPerPage(4);
                } else if (window.innerWidth < 1440) {
                    setDynamicBooksPerPage(6);
                } else {
                    setDynamicBooksPerPage(8);
                }
            };
            updateBooksPerPage();
            window.addEventListener('resize', updateBooksPerPage);
            return () => {
                window.removeEventListener('resize', updateBooksPerPage);
            };
        }
    }, [booksPerPage, variant]);

    let currentBooks;
    if (variant === 'home') {
        currentBooks = books.slice(0, 3);
    } else {
        const effectiveBooksPerPage = booksPerPage ?? dynamicBooksPerPage;
        const startIndex = (currentPage - 1) * effectiveBooksPerPage;
        const endIndex = startIndex + effectiveBooksPerPage;
        currentBooks = books.slice(startIndex, endIndex);
    }

    // const goReadingPage = (book_id, i) => {
    //     console.log('book+_id : ', book_id);
    //     localStorage.setItem('book_id', book_id);
    //     navigate('/reading', {
    //         state: {
    //             bookList: books[i],
    //             pageName: pageName,
    //             bookID: book_id,
    //         },
    //     });
    // };

    return (
        <ul className="box-conts">
            {currentBooks.map((book, index) => (
                <BookCover
                    key={book.id || index}
                    {...book}
                    index={index}
                    variant={variant}
                    // onClick={() => {
                    //     if (onStorySelect) {
                    //         onStorySelect(book);
                    //     } else {
                    //         goReadingPage(book.id, index);
                    //     }
                    // }}
                    onClick={() => {
                        // 클릭 시 부모에서 전달한 onStorySelect 함수 호출
                        // onStorySelect가 전달되지 않으면 아무런 동작도 하지 않음
                        if (onStorySelect) {
                            onStorySelect(book);
                        }
                    }}
                />
            ))}
        </ul>
    );
};

export default BookCoverList;
