import React from 'react';
import '../../../styles/utils/button/ArrowButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ArrowButton = ({ onClick, label, direction }) => {
    const arrowIcon = direction === 'left' ? faArrowLeft : faArrowRight;
    return (
        <button
            className={`arrow-button ${direction}`}
            onClick={onClick}
        >
            <span>{label}</span>
            <FontAwesomeIcon icon={arrowIcon} />
        </button>
    );
};

export default ArrowButton;
