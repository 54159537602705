import React from 'react';
import { useLocation } from 'react-router-dom';
import '../../styles/utils/CategoryList.scss';

const CategoryList = ({ categories, selectedCategory, onSelectCategory }) => {
    const location = useLocation();
    const strokeColor =
        location.pathname === '/myStorybook' ? '#FE8498' : '#FDA61C';
    return (
        <ul className="category-list">
            {categories.map((category) => (
                <li
                    key={category}
                    className={selectedCategory === category ? 'active' : ''}
                    onClick={() => onSelectCategory(category)}
                >
                    {category}
                    {selectedCategory === category && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="20"
                            viewBox="0 0 13 20"
                            fill="none"
                        >
                            <path
                                d="M2.49121 2.80811L10.0577 10.3746L2.49121 17.941"
                                stroke={strokeColor}
                                strokeWidth="4"
                                strokeLinecap="round"
                            />
                        </svg>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default CategoryList;
