import React, { useEffect, useState } from 'react';

const StoryImage = ({ src, alt }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        setIsImageLoaded(false);
    }, [src]);

    const handleImageLoad = () => {
        setTimeout(() => {
            setIsImageLoaded(true);
        }, 100);
    };

    return (
        <figure
            className="stories-bg"
            style={{ position: 'relative', minHeight: '300px' }}
        >
            {!isImageLoaded && (
                <div className="spinner-container">
                    <div className="spinner" />
                </div>
            )}
            <img
                src={src}
                alt={alt}
                onLoad={handleImageLoad}
                style={{
                    opacity: isImageLoaded ? 1 : 0.8,
                    transition: 'opacity 0.5s ease-in-out',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#1B1B1B',
                }}
            />
        </figure>
    );
};

export default StoryImage;
