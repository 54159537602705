import React from 'react';
import { motion } from 'framer-motion';
// NOTE (0214) : 애니메이션 효과 어떤게 좋을지 몰라서 4개 다 넣어놨습니답! 하나씩 주석 풀어보면 효과 바로 확인 가능하신데 저는 개인적으로 음,, 4번 아님 1,2번 괜찮은거같은데 조이님 생각은 어떠신가요?

// ------------1. 왼쪽에서 오른쪽으로 들어오는 효과------------
// const pageVariants = {
//     initial: {
//         opacity: 0,
//         x: -50,
//     },
//     in: {
//         opacity: 1,
//         x: 0,
//     },
//     out: {
//         opacity: 0,
//         x: 50,
//     },
// };

// const pageTransition = {
//     duration: 0.5,
//     ease: 'easeInOut',
// };
// ----------------------------------------------------

// ------------2. 아래에서 위로 올라오는 효과------------
// const pageVariants = {
//     initial: {
//         opacity: 0,
//         y: 50,
//     },
//     in: {
//         opacity: 1,
//         y: 0,
//     },
//     out: {
//         opacity: 0,
//         y: -50,
//     },
// };

// const pageTransition = {
//     duration: 0.5,
//     ease: 'easeOut',
// };
// ------------------------------------------------

// ------------3. 회전 효과------------
// const pageVariants = {
//     initial: {
//         opacity: 0,
//         scale: 0.8,
//         rotate: -10,
//     },
//     in: {
//         opacity: 1,
//         scale: 1,
//         rotate: 0,
//     },
//     out: {
//         opacity: 0,
//         scale: 1.2,
//         rotate: 10,
//     },
// };

// const pageTransition = {
//     type: 'spring',
//     stiffness: 50,
//     damping: 20,
//     duration: 0.8,
// };
// -----------------------------------

// ------------4.줌 효과 (약간 크게 시작해서 작아짐)------------
const pageVariants = {
    initial: {
        opacity: 0,
        scale: 1.1,
    },
    in: {
        opacity: 1,
        scale: 1,
    },
    out: {
        opacity: 0,
        scale: 0.9,
    },
};

const pageTransition = {
    duration: 0.5,
    ease: 'easeInOut',
};
// --------------------------------------------------

const Wrapper = ({ children, ...rest }) => {
    return (
        <motion.div
            variants={pageVariants}
            initial="initial"
            animate="in"
            exit="out"
            transition={pageTransition}
        >
            {children}
        </motion.div>
    );
};

export default Wrapper;
