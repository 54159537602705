import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/utils/loading/QXQuizLoading.scss';
import ProgressBar from '../ProgressBar';
import think from '../../../assets/think-arial.json';
import Lottie from 'lottie-react';
import OngoingModal from '../modal/OngoingModal';
// import '../../../styles/utils/loading/OXQuizLoading.scss';
// import OngoingModal from '../modal/OngoingModal';
// import Progress from '../Progress';
// import PropTypes from 'prop-types';

const QXQuizLoading = ({
    questions = [
        {
            question: 'AI는 개인의 프라이버시를 침해할 위험이 없다.',
            answer: 'X',
        },
        {
            question: 'AI 시스템은 인간의 편견을 그대로 학습할 수 있다.',
            answer: 'O',
        },
        {
            question: 'AI는 인간의 일자리를 완전히 대체할 수 있다.',
            answer: 'X',
        },
        {
            question: 'AI는 교육 분야에서 개인 맞춤형 학습을 제공할 수 있다.',
            answer: 'O',
        },
        {
            question: 'AI는 의료 진단에서 인간 의사를 완전히 대체할 수 있다.',
            answer: 'X',
        },
        {
            question: 'AI는 항상 올바른 결정을 내린다.',
            answer: 'X',
        },
        {
            question: 'AI는 음악을 만들거나 그림을 그릴 수 있다.',
            answer: 'O',
        },
        {
            question: 'AI는 스스로 감정을 느낄 수 있다.',
            answer: 'X',
        },
        {
            question: 'AI는 인간처럼 언어를 이해하고 대화할 수 있다.',
            answer: 'O',
        },
        {
            question: 'AI를 사용하면 농작물을 더욱 효율적으로 관리할 수 있다.',
            answer: 'O',
        },
        {
            question: 'AI는 로봇과 똑같은 의미이다.',
            answer: 'X',
        },
        {
            question: 'AI로 교통 상황을 예측하여 안전을 높일 수 있다.',
            answer: 'O',
        },
        {
            question: 'AI는 스스로 개발한 규칙에 따라 모든 일을 결정한다.',
            answer: 'X',
        },
        {
            question: 'AI를 활용하면 질병 진단에 도움을 줄 수 있다.',
            answer: 'O',
        },
        {
            question: 'AI는 인간이 하는 모든 일을 할 수 있다.',
            answer: 'X',
        },
        {
            question: 'AI는 여러 언어를 번역하는 데 활용될 수 있다.',
            answer: 'O',
        },
        {
            question: 'AI는 사람들의 일만 줄이는 데만 쓰인다.',
            answer: 'X',
        },
        {
            question: 'AI 덕분에 새로운 직업이 생겨날 수도 있다.',
            answer: 'O',
        },
        {
            question: 'AI는 사람의 기분을 100% 정확하게 알아낼 수 있다.',
            answer: 'X',
        },
        {
            question: 'AI는 자연재해를 예측하고 대비하는 데 도움을 줄 수 있다.',
            answer: 'O',
        },
    ],
    initialTimer = 20,
    imagePaths = {
        default: think,
        correct: '/images/utils/loading/correct-arial.svg',
        wrong: '/images/utils/loading/wrong-arial.svg',
        timeout: '/images/utils/loading/timeout-arial.svg',
    },
    feedbackDuration = 3000,
    title,
    flag,
    end,
    onEnd,
    timeCount,
}) => {
    const [currentQuestion, setCurrentQuestion] = useState(() =>
        Math.floor(Math.random() * questions.length)
    );
    const [feedback, setFeedback] = useState('');
    const [timer, setTimer] = useState(initialTimer); // 기본 타이머 20초
    const [imageStatus, setImageStatus] = useState('default');
    const audioRef = useRef(null);
    const USER_ID = localStorage.getItem('userID');
    const [countDown, setCountDown] = useState(timeCount);
    const [isPlaying, setIsPlaying] = useState(true);
    const [showOngoingModal, setShowOngoingModal] = useState(false);
    const [isBackgroundImageChanged, setIsBackgroundImageChanged] =
        useState(false); //NOTE: completed되면 배경 변함

    useEffect(() => {
        if (end) {
            setIsBackgroundImageChanged(true);
            const interval = setInterval(() => {
                onEnd();
            }, 3000);

            return () => {
                clearInterval(interval);
            };
        } else {
            setIsBackgroundImageChanged(false);
        }
    }, [end]);

    const handleAudioPlay = () => {
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play().catch((error) => {
                    console.error('오디오 재생에 실패했습니다.', error);
                });
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    // 시계 카운트다운
    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((prevCountDown) => prevCountDown - 1);
        }, 1000);

        if (countDown === 0) {
            clearInterval(interval);
            setShowOngoingModal(true);
        }
        return () => clearInterval(interval);
    }, [countDown]);

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    setFeedback('시간이 다 되었어요!');
                    setImageStatus('timeout');
                    setTimeout(() => {
                        setFeedback('');
                        setCurrentQuestion(
                            Math.floor(Math.random() * questions.length)
                        );
                        setTimer(initialTimer);
                        setImageStatus('default');
                    }, feedbackDuration);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(countdown);
    }, [currentQuestion, initialTimer, feedbackDuration, questions.length]);

    const handleAnswer = (userAnswer) => {
        if (userAnswer === questions[currentQuestion].answer) {
            setFeedback('정답이에요! 🎉');
            setImageStatus('correct');
        } else {
            setFeedback('틀렸어요! 😢');
            setImageStatus('wrong');
        }

        setTimeout(() => {
            setFeedback('');
            setCurrentQuestion(Math.floor(Math.random() * questions.length));
            setTimer(initialTimer);
            setImageStatus('default');
        }, feedbackDuration);
    };

    const defaultLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: think,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const renderAnimation = () => {
        if (imageStatus === 'default') {
            return (
                <Lottie
                    animationData={think}
                    loop={true}
                    autoplay={true}
                    style={{ height: `29%` }}
                />
            );
        } else {
            let src;
            switch (imageStatus) {
                case 'correct':
                    src = imagePaths.correct;
                    break;
                case 'wrong':
                    src = imagePaths.wrong;
                    break;
                case 'timeout':
                    src = imagePaths.timeout;
                    break;
                default:
                    src = imagePaths.default;
            }
            return (
                <img
                    src={src}
                    alt="Quiz Illustration"
                />
            );
        }
    };

    const getImageSrc = () => {
        switch (imageStatus) {
            case 'correct':
                return imagePaths.correct;
            case 'wrong':
                return imagePaths.wrong;
            case 'timeout':
                return imagePaths.timeout;
            default:
                return imagePaths.default;
        }
    };

    return (
        <div className="quiz-container">
            <audio
                ref={audioRef}
                src="/audio/AIMagickid.mp3"
                loop
            />
            <div className="clock">
                <figure>
                    <img
                        src={'/images/utils/clock.svg'}
                        alt="clock"
                    />
                </figure>
                <p className={`count-down ${countDown <= 10 ? 'red' : ''}`}>
                    {countDown}
                </p>
            </div>
            <button
                className="music-play-btn"
                onClick={handleAudioPlay}
            >
                <figure>
                    <img
                        src={
                            isPlaying
                                ? '/images/utils/button/music-on.svg'
                                : '/images/utils/button/music-off.svg'
                        }
                        alt="music-play"
                    />
                </figure>
            </button>
            <ProgressBar />
            <div className="inner">
                <div className="quiz-box">
                    <div className="quiz-title">
                        <h1 className="title">OX 퀴즈</h1>
                        <div className="timer">남은 시간: {timer}초</div>
                    </div>

                    <div className="quiz-main">
                        <figure className="quiz__image">
                            {renderAnimation()}
                        </figure>
                        <div className="quiz-wrap">
                            <div className="question">
                                {questions[currentQuestion].question}
                            </div>
                            <div className="answer-buttons">
                                <button
                                    className="btn btn-o"
                                    onClick={() => handleAnswer('O')}
                                >
                                    O
                                </button>
                                <button
                                    className="btn btn-x"
                                    onClick={() => handleAnswer('X')}
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showOngoingModal && (
                <OngoingModal onclose={() => setShowOngoingModal(false)} />
            )}
        </div>
    );
};

export default QXQuizLoading;
