import React, { useState } from 'react';
import '../../../styles/utils/modal/AllViewModal.scss';
import BookCover from '../BookCover';

const AllViewModal = ({ bookList, onClose, onStorySelect }) => {
    const [selectedBook, setSelectedBook] = useState(null);

    const handleSelect = (book) => {
        setSelectedBook(book);
    };

    const handleConfirm = () => {
        if (selectedBook && onStorySelect) {
            onStorySelect(selectedBook);
        }
    };

    return (
        <section className="all-view-section">
            <div className="inner">
                <figure className="title">
                    <img
                        src={'/images/utils/modal/all-view-tit.svg'}
                        alt=""
                    />
                </figure>
                <figure
                    className="close-btn"
                    onClick={onClose}
                >
                    <img
                        src={'/images/utils/button/close-btn-green.svg'}
                        alt=""
                    />
                </figure>
                <div className="all-view-container">
                    <ul className="all-view-list">
                        {bookList.map((book, index) => (
                            <BookCover
                                key={book.id || index}
                                {...book}
                                onClick={() => handleSelect(book)}
                                className={
                                    selectedBook &&
                                    selectedBook.story_id === book.story_id
                                        ? 'selected'
                                        : ''
                                }
                            />
                        ))}
                    </ul>
                    {selectedBook && (
                        <div className="confirm-btn-wrapper">
                            <figure
                                className="confirm-btn"
                                onClick={handleConfirm}
                            >
                                <img
                                    src={
                                        '/images/utils/button/confirm-btn-blue.svg'
                                    }
                                    alt=""
                                />
                            </figure>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AllViewModal;
