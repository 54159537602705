import React from 'react';
import '../../styles/reading/FinalReadingModal.scss';
import { useNavigate } from 'react-router-dom';

const FinalReadingModal = ({ onBack, pageName, onClose }) => {
    // NOTE: 처음부터 다시 읽기 or 나만의 서재로 돌아가기
    const navigate = useNavigate();
    const goPage = () => {
        navigate(`/${pageName}`);
    };
    const goAIMaigkid = () => {
        window.close();
    };

    return (
        <>
            <section className="rechoice-section">
                <div className="inner">
                    {/* 2024-02-13 | 모달 닫기 버튼 추가 */}
                    <figure
                        className="close-modal-btn"
                        onClick={onClose}
                    >
                        <img
                            src={'/images/utils/close.png'}
                            alt=""
                        />
                    </figure>
                    <p
                        className="re-btn from-beginning-btn"
                        onClick={() => onBack()}
                    >
                        처음부터 다시 읽기
                    </p>
                    {pageName === 'aimagickid' ? (
                        <>
                            <p
                                className="re-btn my-library-btn"
                                onClick={() => goAIMaigkid()}
                            >
                                AI매직포유로 돌아가기
                            </p>
                        </>
                    ) : (
                        <>
                            <p
                                className="re-btn my-library-btn"
                                onClick={() => goPage()}
                            >
                                {pageName === 'storyland'
                                    ? '스토리랜드로 돌아가기'
                                    : '나만의 서재로 돌아가기'}
                            </p>
                        </>
                    )}
                </div>
            </section>
        </>
    );
};

export default FinalReadingModal;
