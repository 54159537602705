import React from 'react';
import '../../styles/utils/SeriesBookList.scss';
import BookCover from './BookCover';

const SeriesBookList = ({ books, onStorySelect }) => {
    const groupedBooks = books.reduce((groups, book) => {
        const seriesName = book.series_name || '기타';
        if (!groups[seriesName]) {
            groups[seriesName] = [];
        }
        groups[seriesName].push(book);
        return groups;
    }, {});

    return (
        <div className="series-book-list">
            {Object.entries(groupedBooks).map(([seriesName, seriesBooks]) => (
                <div
                    key={seriesName}
                    className="series-group"
                >
                    <h4 className="series-title">{seriesName}</h4>
                    <ul className="series-books">
                        {seriesBooks.map((book, index) => (
                            <BookCover
                                key={book.id}
                                {...book}
                                index={index}
                                variant="series"
                                onClick={() =>
                                    onStorySelect && onStorySelect(book)
                                }
                            />
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default SeriesBookList;
