import './Fonts/Font.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { GlobalStyles } from './styled';
import { AuthProvider } from './AuthContext';
import { SelectedBookProvider } from './SelectedBookContext';
import MyStorybook from './pages/MyStorybook';
import Reading from './pages/Reading';
import Making from './pages/Making';
import Selecting from './pages/Selecting';
import StoryLand from './pages/StoryLand';
import BranchModal from './components/creating/modal/BranchModal';
import Header from './components/utils/Header';
import Footer from './components/utils/Footer';
import Loading from './components/utils/loading/Loading';
import ReadingbyAIMagkickid from './pages/ReadingbyAIMagickid';
import ExpiredModal from './components/utils/modal/ExpiredModal';
import FromMagickid from './pages/FromMagickid';
import Heart from './components/utils/Heart';
import HeaderController from './components/utils/HeaderController';
import Home from './pages/Home';
import ShortLoading from './components/utils/loading/ShortLoading';
import ModifyModal from './components/utils/modal/ModifyModal';
import NovelReading from './components/reading/NovelReading';
import AllViewModal from './components/utils/modal/AllViewModal';
import OXQuizLoading from './components/utils/loading/QXQuizLoading';
import { AnimatePresence } from 'framer-motion';
import QXQuizLoading from './components/utils/loading/QXQuizLoading';

function App() {
    const [hearts, setHearts] = useState([]);

    const handleHeartClick = (event) => {
        const x = event.pageX;
        const y = event.pageY;
        const now = Date.now();
        setHearts((prevHearts) => [
            ...prevHearts,
            { id: now, left: x, top: y },
        ]);
        setTimeout(() => {
            setHearts((current_hearts) =>
                current_hearts.filter((heart) => heart.id !== now)
            );
        }, 500);
    };

    useEffect(() => {
        document.addEventListener('click', handleHeartClick);

        //NOTE: 우클릭방지 처리
        const handleRightClick = (event) => {
            event.preventDefault();
        };

        document.addEventListener('contextmenu', handleRightClick);

        return () => {
            document.removeEventListener('contextmenu', handleRightClick);
        };
    }, []);

    // const RenderHeader = () => {
    //     const location = useLocation();
    //     if (location.pathname === '/reading-aimagickid') {
    //         return null;
    //     }
    //     return <Header />;
    // };

    return (
        <AuthProvider>
            <SelectedBookProvider>
                <Router>
                    <GlobalStyles />
                    <HeaderController />
                    {hearts.map(({ id, left, top }) => (
                        <Heart
                            key={id}
                            left={left}
                            top={top}
                        />
                    ))}
                    <AnimatePresence>
                        <Routes>
                            <Route
                                path="/home"
                                element={<Home />}
                            />
                            <Route
                                path="/ox"
                                element={<QXQuizLoading />}
                            />
                            <Route
                                path="/all"
                                element={<AllViewModal />}
                            />
                            <Route
                                path="/myStorybook"
                                element={<MyStorybook />}
                            />
                            <Route
                                path="/reading"
                                element={<Reading />}
                            />
                            <Route
                                path="/novel"
                                element={<NovelReading />}
                            />
                            <Route
                                path="/reading-aimagickid"
                                element={<ReadingbyAIMagkickid />}
                            />
                            <Route
                                path="/making/:selectedBookId"
                                element={<Making />}
                            />
                            <Route
                                path="/branch"
                                element={<BranchModal />}
                            />
                            <Route
                                path="/storyland"
                                element={<StoryLand />}
                            />
                            <Route
                                path="/selecting"
                                element={<Selecting />}
                            />
                            <Route
                                path="/loading"
                                element={<Loading />}
                            />
                            <Route
                                path="/logout"
                                element={<ExpiredModal />}
                            />
                            <Route
                                path="/check"
                                element={<FromMagickid />}
                            />
                        </Routes>
                    </AnimatePresence>
                </Router>
            </SelectedBookProvider>
        </AuthProvider>
    );
}

export default App;
