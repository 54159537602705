import React, { useState } from 'react';
import '../../styles/utils/BookCover.scss';

const BookCover = ({
    title,
    bookcover,
    reading_count,
    onClick,
    index,
    variant,
    className,
}) => {
    let fillColor = '#000000';

    const [isLoaded, setIsLoaded] = useState(false);

    if (variant === 'home') {
        if (index === 0) {
            fillColor = '#FCC92C';
        } else if (index === 1) {
            fillColor = '#4169E1';
        } else if (index === 2) {
            fillColor = '#FF8C00';
        }
    }
    return (
        <li
            className={`book-cover ${className || ''}`}
            onClick={onClick}
        >
            {variant === 'home' && (
                <div className="grade-tag">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="41"
                        height="52"
                        viewBox="0 0 41 52"
                        fill="none"
                    >
                        <path
                            d="M0.517578 0.117676H40.1436V51.9889L20.4331 41.8439L0.517578 51.9889V0.117676Z"
                            fill={fillColor}
                        />
                    </svg>
                    <span>{index + 1}</span>
                </div>
            )}
            {/* <figure>
                <img
                    src={bookcover}
                    alt={title}
                    loading="lazy"
                />
            </figure> */}
            <figure>
                {!isLoaded && (
                    <div className="cover-placeholder">
                        <div className="spinner"></div>
                    </div>
                )}
                <img
                    src={bookcover}
                    alt={title}
                    onLoad={() => setIsLoaded(true)}
                    style={{
                        opacity: isLoaded ? 1 : 0,
                        transition: 'opacity 0.5s ease-in-out',
                    }}
                    loading="lazy"
                />
            </figure>
            <p className="best-title">{title}</p>
            <div className="book-shadow"></div>
            <div className="book-white"></div>
        </li>
    );
};

export default BookCover;
