import React from 'react';
import '../../styles/reading/NovelReading.scss';

const NovelReading = () => {
    return (
        <section className="novel-section">
            <div className="inner">
                <div className="reading-container">
                    <figure className="reading-book-bg">
                        <img
                            src={'/images/book/novel-2.svg'}
                            alt=""
                        />
                    </figure>
                    <div className="novel-contents">
                        <figure className="novel-image">
                            <img
                                src={'/images/testStoryland/novel-test-img.png'}
                                alt=""
                            />
                        </figure>
                        <div className="novel-text-container">
                            <p>
                                AI는 그림을 그리고, 음악을 만들고, 글도 쓸 수
                                있어요! 예를 들면, 우리가 몇 가지 키워드를
                                입력하면 AI가 새로운 그림을 만들어 줄 수도
                                있어요. 🎨🎵 AI는 그림을 그리고, 음악을 만들고,
                                글도 쓸 수 있어요! 예를 들면, 우리가 몇 가지
                                키워드를 입력하면 AI가 새로운 그림을 만들어 줄
                                수도 있어요. 🎨🎵 AI는 그림을 그리고, 음악을
                                만들고, 글도 쓸 수 있어요! 예를 들면, 우리가 몇
                                가지 키워드를 입력하면 AI가 새로운 그림을 만들어
                                줄 수도 있어요. 🎨🎵
                            </p>
                            <figure className="next-page-btn">
                                <img
                                    src={'/images/book/next-page.svg'}
                                    alt=""
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NovelReading;
