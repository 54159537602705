import React from 'react';
import '../../../styles/utils/modal/OngoingModal.scss';

const OngoingModal = ({ onClose }) => {
    return (
        <section className="ongoing-section">
            <div className="inner">
                <figure>
                    <img
                        src={'/images/utils/modal/busy-now.svg'}
                        alt=""
                    />
                </figure>
                <div className="txt-container">
                    <p>
                        AI마법사가 지금 너무 바쁜가봐요. 조금만 더 기다려주세요.
                    </p>
                    <button onClick={onClose}>확인</button>
                </div>
            </div>
        </section>
    );
};

export default OngoingModal;
