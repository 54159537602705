import React, { useState } from 'react';
import '../../../styles/utils/modal/ModifyModal.scss';

const ModifyModal = ({ originalContent, onConfirm, onClose }) => {
    const [modifiedContent, setModifiedContent] = useState('');

    return (
        <section className="modify-section">
            <div className="inner">
                <figure className="modify-tit">
                    <img
                        src={'/images/utils/modal/modify-tit.svg'}
                        alt=""
                    />
                </figure>
                <div className="modify-box">
                    <div className="modify-container">
                        {/* <div className="modify left">
                            <h3 className="title">기존 동화 내용</h3>
                            <p>{originalContent}</p>
                        </div> */}
                        <div className="modify right">
                            <h3 className="title">동화를 어떻게 바꿔볼까요?</h3>
                            <textarea
                                className="modify-area"
                                placeholder="수정할 내용 입력하세요 :)"
                                value={modifiedContent}
                                onChange={(e) =>
                                    setModifiedContent(e.target.value)
                                }
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="btn-container">
                    <div
                        className="confirm-btn"
                        onClick={() => {
                            onConfirm(modifiedContent);
                            onClose();
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="139"
                            height="63"
                            viewBox="0 0 139 63"
                            fill="none"
                        >
                            <circle
                                cx="30.122"
                                cy="32.8501"
                                r="29.5576"
                                fill="#7AB450"
                            />
                            <circle
                                cx="109.428"
                                cy="32.8349"
                                r="29.5576"
                                fill="#7AB450"
                            />
                            <rect
                                x="30.1221"
                                y="3.29248"
                                width="79.3058"
                                height="59.1001"
                                fill="#7AB450"
                            />
                            <circle
                                cx="30.122"
                                cy="29.8618"
                                r="29.5576"
                                fill="#8BD15C"
                            />
                            <circle
                                cx="109.428"
                                cy="29.8466"
                                r="29.5576"
                                fill="#8BD15C"
                            />
                            <rect
                                x="30.1221"
                                y="0.304199"
                                width="79.3058"
                                height="59.1001"
                                fill="#8BD15C"
                            />
                        </svg>
                        <p className="modify-text">수정하기</p>
                    </div>
                    <div
                        className="close-btn"
                        onClick={onClose}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="139"
                            height="64"
                            viewBox="0 0 139 64"
                            fill="none"
                        >
                            <circle
                                cx="29.9111"
                                cy="33.4985"
                                r="29.5576"
                                fill="#565656"
                            />
                            <circle
                                cx="109.217"
                                cy="33.4834"
                                r="29.5576"
                                fill="#565656"
                            />
                            <rect
                                x="29.9111"
                                y="3.94092"
                                width="79.3058"
                                height="59.1001"
                                fill="#565656"
                            />
                            <circle
                                cx="29.9111"
                                cy="30.5102"
                                r="29.5576"
                                fill="#7B7B7B"
                            />
                            <circle
                                cx="109.217"
                                cy="30.4951"
                                r="29.5576"
                                fill="#7B7B7B"
                            />
                            <rect
                                x="29.9111"
                                y="0.952637"
                                width="79.3058"
                                height="59.1001"
                                fill="#7B7B7B"
                            />
                        </svg>
                        <p className="modify-text">닫기</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ModifyModal;
